<template>
    <ValidationObserver ref="studentPlacement">
        <b-row>
            <b-col rows="12" md="6" lg="6">
                <ValidationProvider name="semester_id" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('semester')">
                        <semesters-selectbox
                            :validate-error="errors[0]"
                            :setActive="true"
                            v-model="defineForm.semester_id">
                        </semesters-selectbox>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <ValidationProvider name="course_id" v-slot="{ valid, errors }">
                    <b-form-group :label="$t('course')">
                        <course-auto-complete v-model="defineForm.course_id"
                                              :validateError="errors[0]"/>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <b-col rows="12" md="6" lg="6">
                <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('type')">
                        <parameter-selectbox
                            :code="'exam_schedules_types'"
                            :validate-error="errors[0]"
                            v-model="defineForm.type"/>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col rows="12" md="6" lg="6">
                <ValidationProvider name="order" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('order')">
                        <parameter-selectbox
                            :code="'exam_schedule_order_types'"
                            :validate-error="errors[0]"
                            v-model="defineForm.order"/>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col rows="12" md="12" lg="12">
                <div class="d-flex justify-content-center mb-5">
                    <b-button variant="primary" @click="studentPlacement">
                        {{ $t('placement_student') }}
                    </b-button>
                </div>
            </b-col>
        </b-row>
    </ValidationObserver>
</template>

<script>
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox.vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";

import ExamScheduleService from "@/services/ExamScheduleService";
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete.vue";


export default {
    components: {
        CourseAutoComplete,
        ParameterSelectbox,
        SemestersSelectbox,
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            defineForm: {}
        }
    },
    methods: {
        studentPlacement() {
            const formData = new FormData();
            formData.append("semester_id", this.defineForm.semester_id);
            formData.append("type", this.defineForm.type);
            formData.append("order", this.defineForm.order);
            if(this.defineForm.course_id){
                formData.append("course_id", this.defineForm.course_id);
            }

            ExamScheduleService.examSchedulesStudentPlacement(formData)
                .then(response => {
                    this.$toast.success('proccess_success');
                    this.$emit('formSuccess');
                    // this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                })
                .catch(e => this.showErrors(e,this.$refs.studentPlacement))
        }
    }
}
</script>
