<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="course_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('course')">
                            <course-auto-complete v-model="formData.course_id"
                                                  :valueObject="courseValueObject"
                                                  :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="section" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('section')">
                            <b-form-input type="number"
                                          min="1"
                                          v-check-min-max-value
                                          v-model="formData.section"
                                          :placeholder="$t('section')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="type" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('exam_type')">
                            <parameter-selectbox v-model="formData.type"
                                                 :code="'exam_schedules_types'"
                                                 :validateError="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="date" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('exam_date')">
                            <b-form-input type="date"
                                          v-model="formData.date"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="start_time" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('start_time')">
                            <b-form-input type="time"
                                          v-model="formData.start_time"
                                          :state="errors[0] ? false:null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="end_time" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('end_time')">
                            <b-form-input type="time"
                                          v-model="formData.end_time"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="instructor_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('teaching_staff')">
                            <staff-auto-complete v-model="formData.instructor_id"
                                                 :valueObject="instructorValueObject"
                                                 :validateError="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="allow_classroom_overlap" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('allow_classroom_overlap')">
                            <true-false-selectbox v-model="formData.allow_classroom_overlap"
                                                  :validateError="errors[0]"
                                                  :options="[{value: 1,text: $t('yes')},{value: 0,text: $t('no')}]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="allow_instructor_overlap" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('allow_instructor_overlap')">
                            <true-false-selectbox v-model="formData.allow_instructor_overlap"
                                                  :validateError="errors[0]"
                                                  :options="[{value: 1,text: $t('yes')},{value: 0,text: $t('no')}]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" class="mr-3"
                          @click="updateForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
                <b-button variant="secondary" :disabled="formLoading" @click="$refs.editClassroom.$refs.commonModal.show()">
                    {{ $t('edit_classrooms') }}
                </b-button>
            </div>
        </ValidationObserver>
        <CommonModal ref="editClassroom" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="$refs.editClassroom.$refs.commonModal.hide()">
            <template v-slot:CommonModalTitle>
                {{ $t('edit_classrooms') }}
            </template>
            <template v-slot:CommonModalContent>
                <b-row>
                    <b-col cols="6" md="12" lg="6">
                        <p>{{ $t('classroom_list') }}</p>
                        <ValidationProvider name="classroom_list" rules="required" v-slot="{valid,errors}">
                            <checkbox-with-filter
												v-model="formData.classroom_ids"
                                                  :options="classroomOptions"
                                                  :height="500"
                                                  :validate-error="errors[0] ? errors[0]:''"
                            />
                        </ValidationProvider>
                    </b-col>
                    <b-col cols="6" md="12" lg="6">
                        <p>{{ $t('your_selected_classrooms') }}</p>
                        <div class="selected-area">
                            <div class="" v-if="selectedClassroomNames && selectedClassroomNames.length >0">
                                <ul>
                                    <li v-for="selectedClassroom in selectedClassroomNames">
                                        {{ selectedClassroom.name }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </template>
        </CommonModal>
    </div>
</template>

<script>
    // Components
    import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
    import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";
    import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";
    import TrueFalseSelectbox from "@/components/interactive-fields/TrueFalseSelectbox";
    import CommonModal from '@/components/elements/CommonModal.vue'
    import CheckboxWithFilter from "@/components/elements/CheckboxWithFilter";

    // Services
    import ExamScheduleService from "@/services/ExamScheduleService";
    import ClassroomService from "@/services/ClassroomService";

    // Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
	import qs from 'qs';

    export default {
        components: {
            AcademicYearsSelectbox,
            CourseAutoComplete,
            FacultySelectbox,
            ParameterSelectbox,
            StaffAutoComplete,
            TrueFalseSelectbox,
            CommonModal,
            ValidationProvider,
            ValidationObserver,
            CheckboxWithFilter
        },
        props: {
            formId: {
                type: Number,
            }
        },
        data() {
            return {
                classroomOptions: [],
                courseValueObject: {},
                instructorValueObject: {},
                formData: {
                    course_id: null,
                    section: null,
                    type: null,
                    classroom_ids: null,
                    date: null,
                    start_time: null,
                    end_time: null,
                    instructor_id: null,
                    allow_classroom_overlap:null,
                    allow_instructor_overlap:null
                },
                formLoading: false,
            }
        },
        watch: {
            formId: function (val) {
                this.get(val)
            }
        },
		computed: {
			selectedClassroomNames() {
				return this.formData?.classroom_ids?.map((classroomId) => {
					return {
						id: classroomId,
						name: this.classroomOptions.find((co) =>  co.id == classroomId)?.name
					}
				})?.sort((a, b) => a?.name?.localeCompare(b?.name))
			}
		},
        async created() {
            await this.get(this.formId)
            this.getClassroomOptions()
        },
        methods: {
            get(id) {
                ExamScheduleService.get(id)
                                   .then(response => {
                                       let data = response.data.data;
                                       this.formData = {
                                           course_id: data.course_id,
                                           section: data.section,
                                           type: data.type,
                                           classroom_ids: data.classroom_ids,
                                           date: data.date,
                                           start_time: data.start_time,
                                           end_time: data.end_time,
                                           instructor_id: data.instructor_id,
                                           allow_classroom_overlap: data.allow_classroom_overlap == true ? 1 : 0,
                                           allow_instructor_overlap: data.allow_instructor_overlap==true?1:0
                                       }
                                       this.courseValueObject = {
                                           value: data.course_id,
                                           text: data.course_code + ' - ' + data.course_name
                                       }
                                       this.instructorValueObject = {
                                           value: data.instructor_id,
                                           text: data.instructor
                                       }
                                   })
                                   .catch(e => {
                                       this.showErrors(e)
                                   })
            },
            // Section updateForm
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true

                    ExamScheduleService.update(this.formId, this.formData)
                                       .then(response => {
                                           this.$emit('updateFormSuccess', true);
                                           this.$toast.success(this.$t('api.' + response.data.message));
                                       })
                                       .catch(error => {
											if (error.status == 422) {
												if (error.data.errors.classroom_ids) {
													this.$toast.error(this.$t('can_not_empty_classrooms'))
												}
											}
                                            else if (error.status == 406) {
												this.showErrors(e, this.$refs.formModalValidate)
											}
											else {
												this.showErrors(this.$t('api.' + error.data.message))
											}
                                       })
                                       .finally(() => {
                                           this.formLoading = false
                                       })
                }
            },
             getClassroomOptions() {
                let config = {
                    params: {
                        limit: -1,
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                 ClassroomService.getAll(config)
					.then((response) => {
							const data = response.data.data;

							data.map((item) => {
								this.classroomOptions.push({
									id: item.id,
									name: item.name,
								})
							})
						})
            },
        }
    }
</script>


